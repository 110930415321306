import { ChangeEvent, MouseEvent } from "react";
import { Timer } from "../types";

export function isDescendant(parent: string, child?: EventTarget | null) {
  if (!child) return false;

  // @ts-ignore
  var node: HTMLElement = child.parentNode;
  while (node != null && node.tagName !== "document") {
    if (node.className?.split(" ").includes(parent)) {
      return true;
    }
    // @ts-ignore
    node = node.parentNode;
  }
  return false;
}

export function isDescendantOfEl(parent: Node, child: Node): boolean {
  let node: Node | null = child.parentNode;

  while (node !== null) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
}

export function findNearestParent(element: Element, selector: string): Element | undefined {
  if (element.matches(selector)) return element;
  if (element.parentElement) return findNearestParent(element.parentElement, selector);
  return undefined;
}

export function getActiveScrollParent(node) {
  if (node === null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight && node.offsetHeight && node.scrollTop !== 0) {
    return node;
  } else {
    return !!node.parentNode ? getActiveScrollParent(node.parentNode) : null;
  }
}

export const preventPropagation = (e: MouseEvent<HTMLElement> | ChangeEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

/**
 * Waits for a child window to close via polling.
 * @param w The window to poll for
 * @param onClose A callback to call on close
 * @param pollInterval the time between pollings
 * @returns A function to stop polling
 */
export const onChildWindowClose = (w: Window | undefined, onClose: () => unknown, pollInterval = 500): (() => void) => {
  let timer: Timer;
  const stopPolling = () => clearTimeout(timer);
  const continuePolling = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    timer = setTimeout(timerCb, pollInterval);
  };

  const timerCb = async () => {
    if (!w) stopPolling();
    else if (w.closed) {
      stopPolling();
      await onClose();
    } else continuePolling();
  };

  continuePolling();

  return stopPolling;
};
